import * as React from 'react'

import Layout from './index'
import ThemeProvider from 'hendrixmaterial/theme'

export default function WrapPage({element,props}){
  return <ThemeProvider {...props}>
    <Layout {...props}>
        {element}
    </Layout>
  </ThemeProvider>
}
