import Nav from'./nav'

export * from'./appbar'
export * from'./toolbar'
export * from'./nav'
export default Nav

// import Appbar,{AppbarProps}from'./appbar'
// import Toolbar,{ToolbarProps}from'./toolbar'
// import Nav,{NavProps}from'./nav'


// export{Appbar,AppbarProps,Toolbar,ToolbarProps,NavProps}


// import * as React from 'react'
// import{navigate}from '@reach/router'
// import Typo from '@mui/material/Typography'

// import Appbar from './appbar'
// import Toolbar from './toolbar'

// import Button from'hendrixmaterial/button'
// import Box from'hendrixmaterial/box'

// export const refresh=()=>navigate('/')


// export default function Nav(props){
//   const _Title  =<Box
//     children={<Typo variant='h4'>penelopepenny</Typo>}
//   />

//   const _BtnHome  =<Button
//     children={_Title}
//     onClick={refresh}
//   />

//   const TOOLS  =_BtnHome
//   const TOOLBAR=<Toolbar>{TOOLS}</Toolbar>
//   const APPBAR =<Appbar toolbar={TOOLBAR}/>
//   return APPBAR
// }

// export * from'./appbar'
// export * from'./toolbar'
// export * from'./nav'

// export interface INav{
//   appbar:React.ElementType<AppbarProps>,
//   appbarProps:AppbarProps,
//   toolbar:React.ElementType<ToolbarProps>,
//   toolbarProps:ToolbarProps
// }

// const Nav:INav={
//   appbar:Appbar,
//   appbarProps:AppbarProps,
//   toolbar:Toolbar,
//   toolbarProps:ToolbarProps
// }
