import * as React from 'react'
import PropTypes from 'prop-types'
import{Helmet}from 'react-helmet'

import{graphql,useStaticQuery} from'gatsby'

const getMetaArr=(meta,page)=>[
  {name    :`description`   ,content:meta.description},
  {property:`og:title`      ,content:page           },
  {property:`og:description`,content:meta.description},
  {property:`og:type`       ,content:`website`      },
]

export default function SEO({page,...rest}){
  const meta    =useStaticQuery(querySitemeta).site.siteMetadata
  const META_ARR=getMetaArr(meta,page)
  return <Helmet htmlAttributes={{lang:meta.lang}} title={page} titleTemplate={meta.titleTemplate} meta={META_ARR}/>
}

const querySitemeta=graphql`
  query{
    site {
      siteMetadata {
        author
        description
        title
        titleTemplate
        lang
      }
    }
  }
`

SEO.defaultProps = {
  lang:'',
  meta:[],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.string.isRequired,
}
