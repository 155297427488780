const name       ='pattyhendrix'
const ext        ='.ninja'
const author     =name
const url        =name+ext
const description='gatsby+mui+hendrix starter'
const image      ='src/static/svgs/mask-white.svg'
const color      ='#212121'
const lang       ='en-US'
const categories =[]

const config={
  author,
  description,
  title:url,
  short_name:name,
  titleTemplate:`%s | ${url}`,
  url,
  // siteUrl:'pattyhendrix.ninja',
  image,
  icon:image, // This path is relative to the root of the site.
  background_color:color,
  theme_color     :color,
  lang,
  categories,
}

module.exports={
  config,
  name,ext,author,description,url,image,color,lang,categories
}
