import * as React from 'react';
import ButtonMui from '@mui/material/Button';

import{ButtonProps}from'./types';

const Button=React.forwardRef((props:ButtonProps,ref):React.Element<typeof ButtonMui>=><ButtonMui {...props} ref={ref}/>)

ButtonMui.defaultProps={
  children:'Default',
  variant:'text',
  size:'small',
  color:'secondary',
  disabled:false,
  disableElevation:false,
  fullWidth:false,
  onClick:()=>alert('onClick')
}


// Button.defaultProps={
//   children:'Default',
//   variant:'text',
//   size:'small',
//   color:'secondary',
//   disabled:false,
//   disableElevation:false,
//   fullWidth:false,
//   onClick:()=>alert('onClick')
// }

export default Button
