import * as React from "react"
import{navigate}from "gatsby"
import Typo from'@mui/material/Typography'

import Button from 'hendrixmaterial/button'
import Box from 'hendrixmaterial/box'
import SvgExternalLink from'static/svgs/external-link'


const withExternalLink=(title,withMarginTop=true)=><Box sx={{display:'inline-flex',alignItems:'center'}}>
  {title}
  {/* <SvgExternalLink sx={{width:'.7em',height:'.7em',marginTop:withMarginTop?'3px':'0px',marginLeft:'1px'}}/> */}
  <SvgExternalLink sx={{width:'.8em',height:'.8em',marginLeft:'1px'}}/>
</Box>

export const makeLinkWithDescription=(title:string,description:string,onClick)=><>
    <Button
      children={
        <Typo variant='h6' sx={{textDecoration:'underline',fontSize:'1rem'}}>{title}</Typo>
      }
      sx={{
        padding:'0 5px 0 0'
      }}
      onClick={onClick}
    />

    <Box
      children={<Typo variant='subtitle1'>- {description}</Typo>}
      sx={{
        display:'flex',
        alignItems:'flex-end'
      }}
    />
</>

export const appLinks=[
  [
    withExternalLink('swingwizard.ai'),
    'Swing analysis',
    ()=>window.open('https://swingwizard.ai/')
  ],
  [
    'Jeremy Howard Language Model',
    <>Talk deep learning like the real <a href="https://en.wikipedia.org/wiki/Jeremy_Howard_(entrepreneur)" target="_blank" rel="noopener noreferrer">Jeremy Howard</a></>,
    ()=>navigate('jeremy-howard-language-model')
  ],
  [
    withExternalLink('jacksminigames'),
    'Heads or tails, Odd or even, Pick a number, and Rock, paper, scissors',
    ()=>window.open('https://jacksminigames.web.app/')
  ],
  [
    withExternalLink('Penelope Penny'),
    'Website for Penelope Penny & the Bubblegum Dress',
    ()=>window.open('https://penelopepennybooks.com/')
  ]
]


export const appLinksWithDescription=appLinks.map((o,i)=><Box
  key={i}
  sx={{
    display:'flex',
    flexWrap:'wrap',
    marginBottom:'4px'
  }}
  children={makeLinkWithDescription(...o)}
/>)


export const makeContent=(title,links,variant='h3')=><>
  <Typo variant={variant}>{title}:</Typo>
  {links}
</>

export const appContent=makeContent('Apps',appLinksWithDescription)

// function PageHome(props){

//   const apps=<>
//     <Typo variant='h3'>Apps:</Typo>
//     {appLinksWithDescription}
//   </>

//   return <>
//     {apps}
//   </>
// }

// export default PageHome
