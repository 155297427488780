import * as React from 'react';

import Appbar,{AppbarProps} from './appbar';
import Toolbar,{ToolbarProps} from './toolbar';
import Box,{IBoxProps}from'../box'

export interface NavProps{
  appbarProps:AppbarProps,
  toolbarProps:ToolbarProps,
  boxProps:IBoxProps
}

export function Nav(props:NavProps){
  const{appbarProps,toolbarProps,boxProps}=props

  const _toolbar=<Toolbar {...toolbarProps} children={toolbarProps?.children||appbarProps?.children||props?.children}/>
  const _appbar =<Appbar {...appbarProps} children={_toolbar}/>

  return <Box {...boxProps} sx={{flexGrow:1,...boxProps?.sx}}>
    {_appbar}
  </Box>
}

export default Nav
