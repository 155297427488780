import * as React from 'react'

export default function WrapRoot({element}){
  return <>{element}</>
}

// import Mdx from'mdx'

// export default function WrapRoot({element}){
//   return <Mdx>{element}</Mdx>
// }
