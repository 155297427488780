import * as React from 'react';
import ToolbarMui,{ToolbarProps as ToolbarPropsMui} from '@mui/material/Toolbar';

export interface ToolbarProps extends ToolbarPropsMui{}

const Toolbar=React.forwardRef((props:ToolbarProps,ref):React.Element<typeof ToolbarMui>=><ToolbarMui {...props} ref={ref}/>)

// ToolbarMui.defaultProps={
//   disableGutters:true,
//   variant:'dense'
// }

export default Toolbar
