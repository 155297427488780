import * as React from 'react';

import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import Typo from '@mui/material/Typography'

import{navigate}from '@reach/router'

import Nav from'hendrixmaterial/nav'
import Button from'hendrixmaterial/button'
import Box from'hendrixmaterial/box'
import siteConfig from'siteConfig'

import Menu from'./menu'


const _title=<Typo
  variant="h6"
  component="div"
  sx={{flexGrow:3}}
  align='left'
  children={siteConfig.url}
  color='primary'
/>


const _btnTitle=<Button
  children={_title}
  onClick={()=>navigate('/')}
  color='primary'
/>


const _left=<Box
  sx={{
    display:'inline-flex',
    flexGrow:3
  }}
  children={_btnTitle}
/>


const _nav=<Nav
  toolbarProps={{
    children:<>
      {_left}
      {<Menu/>}
    </>,
    variant:'dense'
  }}

  appbarProps={{
    position:'sticky',
    color:'secondary',
  }}
/>


export default function LayoutNav(){
  return _nav
}
