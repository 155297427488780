import * as React from 'react';
import styled from'@emotion/styled'
import CssBaseline from '@mui/material/CssBaseline';

import Nav from './nav'
import Seo from'./seo'


const parseLocation=pathname=>pathname.split('/')[1]

export const getTitle     =pathname=>{
  const parsed=parseLocation(pathname)
  if(parsed.length===0){return 'Home'}
  else                 {return parsed.charAt(0).toUpperCase()+parsed.slice(1)}
}

const Children=styled.div`
  width: 95%;
  margin:auto;
  padding-top:10px;
`

export default function Layout({children,...rest}){
  const PAGE   =getTitle(rest?.location?.pathname)
  const NAV    =<Nav {...rest}/>
  const LAYOUT =<>
    <CssBaseline/>
    {NAV}
    <Seo page={PAGE}/>
    <Children>
      {children}
    </Children>
  </>
  return LAYOUT
}
