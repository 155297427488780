import * as React from 'react';

import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import Typo from '@mui/material/Typography'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import{navigate}from '@reach/router'

import Nav from'hendrixmaterial/nav'
import Button from'hendrixmaterial/button'
import Box from'hendrixmaterial/box'
import siteConfig from'siteConfig'
import Mask from'static/svgs/mask-white.svg'

import{appLinks}from'content/links'



export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const makeItem=(item)=><MenuItem onClick={handleClose}>{item}</MenuItem>


  const _btn=<IconButton
    color="error"
    sx={{height:'5em',width:'5em'}}
    children={<Mask style={{height:'100%',width:'100%'}}/>}
    onClick={handleClick}
  />

  return (
    <>
      {_btn}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Typo variant='h5' sx={{marginLeft:'10px'}}>Apps:</Typo>
        {appLinks.map((o,i)=><MenuItem onClick={()=>{o[2]();handleClose()}} key={i}>{o[0]}</MenuItem>)}
      </Menu>
    </>
  );
}
