import * as React from 'react'

import WrapPage from 'layout/wrapPage'
import WrapRoot from 'layout/wrapRoot'


// import initReactFastclick from 'react-fastclick';
// initReactFastclick();

export const onRouteUpdate=({location,prevLocation})=>console.log('onRouteUpdate',`new:[ ${location.pathname} ] `,`prev:[ ${prevLocation?.pathname} ]`);

export const wrapPageElement=({element,props})=><WrapPage element={element} props={props}/>
export const wrapRootElement=({element,props})=><WrapRoot element={element} props={props}/>

// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== 'production') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render')
//     whyDidYouRender(React, {
//       trackAllPureComponents: true,
//       trackHooks:true,
//       logOwnerReasons: true,
//       logOnDifferentValues: true,
//       // onlyLogs:true,
//       titleColor: "green",
//       diffNameColor: "darkturquoise",
//       runtime: "automatic",
//       hotReloadBufferMs:750,
//       // notifier:({...rest})=>console.log(rest)
//       // notifier:({...rest})=>rest.map(o=>console.log(o,'\n'))
//       // importSource: '@welldone-software/why-did-you-render',
//     })
//   }
// }
