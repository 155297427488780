import * as React from 'react';
import AppbarMui,{AppBarProps as AppBarPropsMui} from '@mui/material/AppBar';

export interface AppbarProps extends AppBarPropsMui{}

export const Appbar=React.forwardRef((props:AppbarProps,ref):React.Element<typeof AppbarMui>=><AppbarMui {...props} ref={ref}/>)

// AppbarMui.defaultProps={
// }

export default Appbar
